import { createContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import Axios from "axios";
import { useAuth0, User } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { App as CapApp } from "@capacitor/app";
import { Auth0Lock, Session } from "auth0lock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBan } from "@fortawesome/free-solid-svg-icons";

import { Auth } from "../types";
import { AUTH_DOMAIN, CLIENT_ID } from "../constants/AuthConstants";
import { isIOS } from "../utils/Mobile";
import { OAuthError } from "@auth0/auth0-react/dist/errors";

interface AuthContextType {
  auth?: Auth,
  setAuth: (auth: Auth) => void,
  userPrefs: any,
  setUserPrefs: (prefs: any) => Promise<void>,
  welcomeCompleted: () => void,
  logoutUser: () => any,
  getUserInfo: () => Promise<User>,
  getUserInfoById: (uid: string | Array<string>) => Promise<User>,
  getUserInfoByEmail: (email: string) => Promise<User>
}

const initialContext: AuthContextType = {
  userPrefs: {},
  setAuth: () => {
  },
  setUserPrefs: () => Promise.resolve(),
  welcomeCompleted: () => {
  },
  logoutUser: () => {
  },
  getUserInfo: () => Promise.resolve({}),
  getUserInfoById: () => Promise.resolve({}),
  getUserInfoByEmail: () => Promise.resolve({})
}
const AuthContext = createContext<AuthContextType>(initialContext);

export class UserNotKnownError extends Error {

  public email

  constructor(email: string, message: string) {
    super(message)
    this.email = email
  }
}

const SERVICE_URL = process.env.REACT_APP_SERVICE_URL || window.location.origin
const ERRORS: Record<string, string> = {
  "unauthorized": "XXX"
}

function AuthProvider({children}: any) {

  const {user, getAccessTokenSilently, logout, handleRedirectCallback, buildAuthorizeUrl, error} = useAuth0()
  const [auth, setAuth] = useState<Auth>()
  const [userPrefs, setPrefs] = useState<Record<string, any> | undefined>()

  useEffect(() => {
    if (!user && Capacitor.isNativePlatform()) {
      if (Capacitor.getPlatform() === 'ios') {
        Auth0Lock.session({clientId: CLIENT_ID, domain: AUTH_DOMAIN})
            .catch(err => console.log("Error retrieving session", err))
            // @ts-ignore
            .then((session: Session) => {
              console.log("Access token", session?.accessToken)
              Axios.defaults.headers.common['Authorization'] = `Bearer ${session?.accessToken}`
              getUserInfo()
                  .catch(err => console.log("Error retrieving user info", err))
                  .then(user => {
                    console.log("userinfo", user);
                    return user
                  })
                  .then(user => setUser(user, session.accessToken))
                  .then(() => Axios.get(`${SERVICE_URL}/api/preferences`)
                      .then(response => setPrefs(response.data))
                      .catch(err => console.log(err)))
            })
      } else {
        const openBrowserAuth = async () => {
          const url = await buildAuthorizeUrl();
          await Browser.open({url});
        }
        CapApp.addListener('appUrlOpen', async ({url}) => {
          if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
            await handleRedirectCallback(url)
          }
        })
        console.log("Opening browser for", Capacitor.getPlatform())
        openBrowserAuth().catch(err => console.log("Error opening browser", err))
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user && !isIOS()) getAccessTokenSilently({detailedResponse: true})
        .catch(err => console.log(err))
        .then((token: any) => {
          Axios.defaults.headers.common['Authorization'] = `Bearer ${token?.access_token || token}`
          setUser(user, token?.access_token || token)
        })
        .then(() => Axios.get(`${SERVICE_URL}/api/preferences`)
            .then(response => setPrefs(response.data))
            .catch(err => console.log(err)))
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const setUser = (user: any, accessToken: string) => {
    const org = user['https://coc.lacasa.care/org'] || user['app_metadata']?.org
    const license = user['https://coc.lacasa.care/license'] || user['app_metadata']?.license
    const avatar = user.picture || user['user_metadata']?.picture
    const roles = user['https://coc.lacasa.care/roles'] || user.roles || []
    const access = user['https://coc.lacasa.care/access'] || user.access || []
    console.log('Authorization', user?.name)
    console.log('License', license)
    console.log('Roles', roles.join(", "))
    console.log('Last login', user.updated_at)
    console.log('Organization', org)
    console.log('Access', access)
    return setAuth({
      org, license, avatar, roles, access,
      sub: user.sub || user.user_id || '',
      email: user.email || '',
      name: user.name || '',
      token: accessToken || '',
      lastLogin: user.updated_at ? new Date(user.updated_at) : new Date()
    })
  }

  const setUserPrefs = (prefs: any): Promise<any> => {
    setPrefs(prefs)
    if (auth) return Axios.post(`${SERVICE_URL}/api/preferences`, prefs)
        .catch(err => console.log('error saving user preferences', err))
    return Promise.resolve()
  }

  const welcomeCompleted = () => {
    if (auth) return Axios.post(`${SERVICE_URL}/api/preferences/welcomeCompleted`, {})
        .then(() => setPrefs({...userPrefs, welcomePassed: true}))
        .catch(err => console.log('error completing the user', err))
    return Promise.resolve()
  }

  const logoutUser = () => {
    setAuth(undefined)
    if (isIOS()) Auth0Lock.logout({clientId: CLIENT_ID, domain: AUTH_DOMAIN})
    else logout()
  }

  const getUserInfo = () => {
    return Axios.get(`${SERVICE_URL}/api/userinfo`).then(response => response.data)
  }

  const getUserInfoByEmail = (email: string) => {
    return Axios.post(`${SERVICE_URL}/api/userinfo`, {email}).then(response => {
      if (response.data.length === 0) throw new UserNotKnownError(email, 'The user is not eligible for a given role.')
      return response.data
    })
  }

  const getUserInfoById = (uid: string | Array<string>) => {
    const body = Array.isArray(uid) ? uid.map(v => ({uid: v})) : {uid}
    return Axios.post(`${SERVICE_URL}/api/userinfo`, body).then(response => {
      if (response.data.length === 0) throw new UserNotKnownError(uid.toString(), 'The user is not eligible for a given role.')
      return response.data
    })
  }

  const AuthError = ({name, description}: any) => (
      <Container>
        <Row>
          <Col sm={12} className="text-center">
            <FontAwesomeIcon icon={faBan} size="5x" className="mt-5 mb-3 text-danger"/>
            <div className="text-dark mb-3">{description}</div>
            {ERRORS[name] && <div className="mb-3 text-dark">{ERRORS[name]}</div>}
            <Button onClick={() => window.location.href = `${window.location.origin}/`}>Return to Login screen</Button>
          </Col>
        </Row>
      </Container>
  )

  return (
      <AuthContext.Provider value={{
        auth, setAuth,
        userPrefs,
        setUserPrefs,
        welcomeCompleted,
        logoutUser,
        getUserInfo,
        getUserInfoById,
        getUserInfoByEmail
      }}>
        {error ? <AuthError name={(error as OAuthError).error} description={error.message}/> : children}
      </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider };
