import { createContext, useContext, useEffect, useState } from 'react';
import Axios, { AxiosResponse } from "axios";

import {
  Alert,
  Appliance,
  Appointment,
  Contact,
  Device,
  DeviceGroup,
  Doc,
  Insight,
  Loadable,
  Med,
  MotionData,
  PathwayConfig,
  Post,
  PostGroup,
  ReadoutPlan,
  Value
} from "../types";
import { DataContext } from "./DataContext";
import { LoadableRecord } from "../types/LoadableRecord";
import { EVERYTHING, MONTH } from "../constants/TimeConstants";

interface DeviceContextType {
  device?: Device,
  deviceGroups: Loadable<DeviceGroup>,
  contacts: Loadable<Contact>,
  posts: Loadable<Post>,
  postGroups?: Loadable<PostGroup>,
  metrics: LoadableRecord<Array<Value>>,
  activity: LoadableRecord<Array<Value>>,
  insights: Loadable<Insight>,
  alerts: Loadable<Alert>,
  appointments: Loadable<Appointment>,
  vitals: Loadable<Value>,
  appliances?: Loadable<Appliance>,
  documents?: Loadable<Doc>,
  meds?: Loadable<Med>,
  pathways?: Loadable<PathwayConfig>,
  metricsInterval: number | undefined,
  activityInterval: number | undefined,
  alertsInterval: number | undefined,
  vitalsInterval: number | undefined,
  documentsInterval: number | undefined,
  insightsInterval: number | undefined,
  postGroup?: PostGroup | undefined,
  readoutPlans?: Loadable<ReadoutPlan> | undefined,
  motionData?: MotionData | undefined,
  setDevice: (device: any) => void,
  saveDevice: (device: Device) => Promise<any>,
  saveContact: (contact: Contact, invite: boolean) => Promise<Contact>,
  removeContact: (contactId: string) => Promise<void>,
  saveAppointment: (appointment: Appointment) => Promise<void>,
  removeAppointment: (appointmentId: string) => Promise<void>,
  addPost: (text: string) => Promise<void>,
  savePostGroup: (group: PostGroup) => Promise<void>,
  setPostGroup: (group: PostGroup | undefined) => void,
  setMetricsInterval: (interval: number | undefined) => void,
  setActivityInterval: (interval: number) => void,
  setAlertsInterval: (interval: number | undefined) => void,
  setVitalsInterval: (interval: number | undefined) => void
  setDocumentsInterval: (interval: number | undefined) => void
  setInsightsInterval: (interval: number | undefined) => void
  saveDocument: (document: Doc, file: File) => Promise<void>,
  saveMed: (med: Med) => Promise<void>,
  removeMed: (medId: string) => Promise<void>,
  savePathway: (pathway: PathwayConfig) => Promise<void>
}

const loadableGroup: Loadable<DeviceGroup> = {loading: true}
const loadableContacts: Loadable<Contact> = {loading: true}
const loadablePosts: Loadable<Post> = {loading: true}
const loadablePostGroups: Loadable<PostGroup> = {loading: true}
const loadableMetrics: LoadableRecord<Array<Value>> = {loading: true}
const loadableActivity: LoadableRecord<Array<Value>> = {loading: true}
const loadableAnomalies: Loadable<Insight> = {loading: true}
const loadableAlerts: Loadable<Alert> = {loading: true}
const loadableApointments: Loadable<Appointment> = {loading: true}
const loadableVitals: Loadable<Value> = {loading: true}
const loadableReadoutPlans: Loadable<ReadoutPlan> = {loading: true}
const loadableAppliances: Loadable<Appliance> = {loading: true}
const loadableDocuments: Loadable<Doc> = {loading: true}
const loadableMeds: Loadable<Med> = {loading: true}
const loadablePathways: Loadable<PathwayConfig> = {loading: true}
// @ts-ignore
// @ts-ignore
const initialContext: DeviceContextType = {
  deviceGroups: loadableGroup,
  contacts: loadableContacts,
  posts: loadablePosts,
  postGroups: loadablePostGroups,
  metrics: loadableMetrics,
  metricsInterval: undefined,
  activity: loadableActivity,
  activityInterval: undefined,
  insights: loadableAnomalies,
  insightsInterval: undefined,
  alerts: loadableAlerts,
  alertsInterval: undefined,
  appointments: loadableApointments,
  vitals: loadableVitals,
  vitalsInterval: undefined,
  readoutPlans: loadableReadoutPlans,
  appliances: loadableAppliances,
  documents: loadableDocuments,
  documentsInterval: undefined,
  meds: loadableMeds,
  pathways: loadablePathways,
  motionData: undefined,
  setDevice: () => {
  },
  saveDevice: () => Promise.resolve(),
  // @ts-ignore
  saveContact: () => Promise.resolve({}),
  removeContact: () => Promise.resolve(),
  saveAppointment: () => Promise.resolve(),
  removeAppointment: () => Promise.resolve(),
  addPost: () => Promise.resolve(),
  savePostGroup: () => Promise.resolve(),
  setPostGroup: () => {
  },
  setMetricsInterval: () => {
  },
  setActivityInterval: () => {
  },
  setAnomaliesInterval: () => {
  },
  setAlertsInterval: () => {
  },
  setVitalsInterval: () => {
  },
  setDocumentsInterval: () => {
  },
  saveDocument: () => Promise.resolve(),
  saveMed: () => Promise.resolve(),
  removeMed: () => Promise.resolve(),
  savePathway: () => Promise.resolve()
}

const DeviceContext = createContext<DeviceContextType>(initialContext);
const SERVICE_URL = process.env.REACT_APP_SERVICE_URL || window.location.origin;

function DeviceProvider({children}: any) {

  const {devices, setDevices} = useContext(DataContext)
  const [device, setDevice] = useState<Device | undefined>()
  const [deviceGroups, setDeviceGroups] = useState<Loadable<DeviceGroup>>({loading: true})
  const [contacts, setContacts] = useState<Loadable<Contact>>({loading: true})
  const [posts, setPosts] = useState<Loadable<Post>>({loading: true})
  const [postGroups, setPostGroups] = useState<Loadable<PostGroup>>({loading: true})
  const [metrics, setMetrics] = useState<LoadableRecord<Array<Value>>>({loading: true})
  const [postGroup, setPostGroup] = useState<PostGroup | undefined>()
  const [metricsInterval, setMetricsInterval] = useState<number | undefined>()
  const [activity, setActivity] = useState<LoadableRecord<Array<Value>>>({loading: true})
  const [activityInterval, setActivityInterval] = useState<number>(MONTH)
  const [insights, setInsights] = useState<Loadable<Insight>>({loading: true})
  const [insightsInterval, setInsightsInterval] = useState<number | undefined>()
  const [alerts, setAlerts] = useState<Loadable<Alert>>({loading: true})
  const [alertsInterval, setAlertsInterval] = useState<number | undefined>()
  const [appointments, setAppointments] = useState<Loadable<Appointment>>({loading: true})
  const [vitals, setVitals] = useState<Loadable<Value>>({loading: true})
  const [vitalsInterval, setVitalsInterval] = useState<number | undefined>()
  const [readoutPlans, setReadoutPlans] = useState<Loadable<ReadoutPlan> | undefined>()
  const [appliances, setAppliances] = useState<Loadable<Appliance> | undefined>()
  const [documents, setDocuments] = useState<Loadable<Doc> | undefined>()
  const [documentsInterval, setDocumentsInterval] = useState<number | undefined>(EVERYTHING)
  const [meds, setMeds] = useState<Loadable<Med> | undefined>()
  const [pathways, setPathways] = useState<Loadable<PathwayConfig> | undefined>()
  const [motionData, setMotionData] = useState<MotionData | undefined>()

  useEffect(() => {
    if (!device) return
    setDeviceGroups({loading: true})
    Axios.get(`${SERVICE_URL}/api/devices/groups`)
        .then(response => setDeviceGroups({loading: false, data: response.data}))
        .catch(err => setDeviceGroups({loading: false, error: err}));
    setContacts({loading: true})
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/contacts`)
        .then(response => response.data.map((a: Contact) => ({...a, createdAt: new Date(a.createdAt)})))
        .then(data => setContacts({loading: false, data}))
        .catch(err => setContacts({loading: false, error: err}))
    setPostGroups({loading: true})
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/postgroups`)
        .then(response => response.data)
        .then(data => setPostGroups({loading: false, data}))
        .catch(err => setPostGroups({loading: false, error: err}))
    setAppointments({loading: true})
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/appointments`)
        .then(response => response.data.map((a: Appointment) => ({...a, createdAt: new Date(a.createdAt), time: new Date(a.time)})))
        .then(data => setAppointments({loading: false, data}))
        .catch(err => setAppointments({loading: false, error: err}))
    setReadoutPlans({loading: true})
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/readoutplans`)
        .then(response => response.data.map((a: Appointment) => ({...a, createdAt: new Date(a.createdAt), time: new Date(a.time)})))
        .then(data => setReadoutPlans({loading: false, data}))
        .catch(err => setReadoutPlans({loading: false, error: err}))
    setAppliances({loading: true})
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/appliances`)
        .then(response => response.data.map((a: Appliance) => ({...a, timestamp: new Date(a.timestamp)})))
        .then(data => setAppliances({loading: false, data}))
        .catch(err => setAppliances({loading: false, error: err}))
    setMeds({loading: true})
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/meds`)
        .then(response => response.data.map((a: Med) => ({...a, createdAt: new Date(a.createdAt)})))
        .then(data => setMeds({loading: false, data}))
        .catch(err => setMeds({loading: false, error: err}))
    setPathways({loading: true})
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/pathways`)
        .then(response => setPathways({loading: false, data: response.data}))
        .catch(err => setPathways({loading: false, error: err}))
  }, [device])

  useEffect(() => {
    if (!device) return
    setPosts({loading: true})
    const opts = postGroup ? {params: {group: postGroup.id}} : {}
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/posts`, opts)
        .then(response => response.data.map((a: Post) => ({...a, createdAt: new Date(a.createdAt)})))
        .then(data => setPosts({loading: false, data}))
        .catch(err => setPosts({loading: false, error: err}))
  }, [device, postGroup])

  useEffect(() => {
    if (!device) return
    setMetrics({loading: true})
    const opts = metricsInterval ? {params: {from: new Date(Date.now() - metricsInterval)}} : {}
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/metrics`, opts)
        .then(response => response.data)
        .then(data => setMetrics({loading: false, data}))
        .catch(err => setMetrics({loading: false, error: err}))
  }, [device, metricsInterval])

  useEffect(() => {
    if (!device) return
    setActivity({loading: true})
    const opts = activityInterval ? {params: {from: new Date(Date.now() - activityInterval)}} : {}
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/activity`, opts)
        .then(response => response.data)
        .then(data => setActivity({loading: false, data}))
        .catch(err => setActivity({loading: false, error: err}))
    setMotionData(undefined)
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/motion`, opts)
        .then(response => setMotionData(response.data))
        .catch(() => setMotionData(undefined))
  }, [device, activityInterval])

  useEffect(() => {
    if (!device) return
    setInsights({loading: true})
    const opts = insightsInterval ? {params: {from: new Date(Date.now() - insightsInterval)}} : {}
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/insights`, opts)
        .then(response => response.data)
        .then(data => setInsights({loading: false, data}))
        .catch(err => setInsights({loading: false, error: err}))
  }, [device, insightsInterval])

  useEffect(() => {
    if (!device) return
    setAlerts({loading: true})
    const opts = alertsInterval ? {params: {from: new Date(Date.now() - alertsInterval)}} : {}
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/alerts`, opts)
        .then(response => response.data)
        .then(data => setAlerts({loading: false, data}))
        .catch(err => setAlerts({loading: false, error: err}))
  }, [device, alertsInterval])

  useEffect(() => {
    if (!device) return
    setVitals({loading: true})
    const opts = vitalsInterval ? {params: {from: new Date(Date.now() - vitalsInterval)}} : {}
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/vitals`, opts)
        .then(response => response.data)
        .then(data => setVitals({loading: false, data}))
        .catch(err => setVitals({loading: false, error: err}))
  }, [device, vitalsInterval])

  useEffect(() => {
    if (!device) return
    setDocuments({loading: true})
    const opts = documentsInterval ? {params: {from: new Date(Date.now() - documentsInterval)}} : {}
    Axios.get(`${SERVICE_URL}/api/devices/${device.id}/documents`, opts)
        .then(response => response.data.map((a: Doc) => ({...a, createdAt: new Date(a.createdAt)})))
        .then(data => setDocuments({loading: false, data}))
        .catch(err => setDocuments({loading: false, error: err}))
  }, [device, documentsInterval])

  const saveDevice = (device: Device) => new Promise((resolve, reject) => {
    if (device.id) {
      Axios.put(`${SERVICE_URL}/api/devices/${device.id}`, device)
          .catch(err => reject(err))
          .then(response => {
            setDevices({...devices, data: devices.data?.map((d: any) => d.id === response?.data.id ? response?.data : d)})
            if (device.id === response?.data.id) setDevice(response.data)
            resolve(devices)
          })
    } else {
      Axios.post(`${SERVICE_URL}/api/devices`, device)
          .catch(err => reject(err))
          .then(response => {
            setDevices({...devices, data: [...devices.data || [], response?.data]})
            resolve(devices)
          })
    }
  })

  const saveContact = (contact: Contact, invite: boolean = false): Promise<Contact> => {
    if (!device) return Promise.reject('no device')
    const handleResponse = (resolve: Function, response: AxiosResponse<Contact>, isNew: boolean) => {
      // @ts-ignore
      const data = isNew ? [...contacts.data, response.data] : contacts.data.map(c => c.id === contact.id ? response.data : c)
      setContacts({loading: false, data})
      resolve(response.data)
    }
    const handleError = (reject: Function, err: any) => {
      setContacts({loading: false, error: err})
      reject(err)
    }

    return new Promise((resolve, reject) => {
      if (invite) {
        Axios.post<Contact, AxiosResponse<Contact>>(`${SERVICE_URL}/api/devices/${device.id}/invite`, contact)
            .then(response => handleResponse(resolve, response, true))
            .catch(err => handleError(reject, err))
      } else if (contact.id && !invite) {
        Axios.put<Contact, AxiosResponse<Contact>>(`${SERVICE_URL}/api/devices/${device.id}/contacts/${contact.id}`, contact)
            .then(response => handleResponse(resolve, response, false))
            .catch(err => handleError(reject, err))
      } else {
        Axios.post<Contact, AxiosResponse<Contact>>(`${SERVICE_URL}/api/devices/${device.id}/contacts`, contact)
            .then(response => handleResponse(resolve, response, true))
            .catch(err => handleError(reject, err))
      }
    })
  }

  const removeContact = (contactId: string) => {
    if (!device) return Promise.reject('no device')
    return Axios.delete<Contact, Contact>(`${SERVICE_URL}/api/devices/${device.id}/contacts/${contactId}`)
        .then(() => setContacts({loading: false, data: contacts.data?.filter(c => c.id !== contactId)}))
        .catch(err => setContacts({loading: false, error: err}))
  }

  const saveAppointment = (appointment: Appointment) => {
    if (!device) return Promise.reject('no device')
    const handleResponse = (resolve: Function, response: any, isNew: boolean) => {
      const update = {...response.data, time: new Date(response.data.time)}
      // @ts-ignore
      const data = isNew ? [...appointments.data, update] : appointments.data.map(c => c.id === appointment.id ? update : c)
      setAppointments({loading: false, data})
      resolve()
    }
    const handleError = (reject: Function, err: any) => {
      setAppointments({loading: false, error: err})
      reject(err)
    }

    return new Promise<void>((resolve, reject) => {
      if (appointment.id) {
        Axios.put<Appointment, Appointment>(`${SERVICE_URL}/api/devices/${device.id}/appointments/${appointment.id}`, appointment)
            .then(response => handleResponse(resolve, response, false))
            .catch(err => handleError(reject, err))
      } else {
        Axios.post<Appointment, Appointment>(`${SERVICE_URL}/api/devices/${device.id}/appointments`, appointment)
            .then(response => handleResponse(resolve, response, true))
            .catch(err => handleError(reject, err))
      }
    })
  }

  const removeAppointment = (appointmentId: string) => {
    if (!device) return Promise.reject('no device')
    const handleResponse = () => setAppointments({loading: false, data: appointments.data?.filter(c => c.id !== appointmentId)})
    const handleError = (err: any) => setAppointments({loading: false, error: err})
    return Axios.delete<Appointment, Appointment>(`${SERVICE_URL}/api/devices/${device.id}/appointments/${appointmentId}`)
        .then(() => handleResponse())
        .catch(err => handleError(err))
  }

  const addPost = (text: string) => {
    if (!device) return Promise.reject('no device')
    const handleResponse = (resolve: Function, response: AxiosResponse<Post>) => {
      const post: Post = {...response.data, createdAt: new Date(response.data.createdAt)}
      // @ts-ignore
      setPosts({loading: false, data: [...posts.data, post]})
      resolve()
    }
    const handleError = (reject: Function, err: any) => {
      setPosts({loading: false, error: err})
      reject(err)
    }

    return new Promise<void>((resolve, reject) => {
      const opts = postGroup ? {params: {group: postGroup.id}} : {}
      Axios.post<string, AxiosResponse<Post>>(`${SERVICE_URL}/api/devices/${device.id}/posts`, {text}, opts)
          .then(response => handleResponse(resolve, response))
          .catch(err => handleError(reject, err))
    })
  }

  const savePostGroup = (group: PostGroup) => {
    if (!device) return Promise.reject('no device')
    const handleResponse = (resolve: Function, response: Contact, isNew: boolean) => {
      // @ts-ignore
      const data = isNew ? [...postGroups.data, response.data] : postGroups.data.map(c => c.id === group.id ? response.data : c)
      setPostGroups({loading: false, data})
      setPostGroup(data.find(pg => pg.id === postGroup?.id))
      resolve()
    }
    const handleError = (reject: Function, err: any) => {
      setPostGroups({loading: false, error: err})
      reject(err)
    }

    return new Promise<void>((resolve, reject) => {
      if (group.id) {
        const mgroup = { ...group, members: group.members.map(m => m.id) }
        Axios.put<Contact, Contact>(`${SERVICE_URL}/api/devices/${device.id}/postgroups/${group.id}`, mgroup)
            .then(response => handleResponse(resolve, response, false))
            .catch(err => handleError(reject, err))
      } else {
        Axios.post<Contact, Contact>(`${SERVICE_URL}/api/devices/${device.id}/postgroups`, group)
            .then(response => handleResponse(resolve, response, true))
            .catch(err => handleError(reject, err))
      }
    })
  }

  const saveDocument = (document: Doc, file: File) => {
    if (!device) return Promise.reject('no device')
    const formData = new FormData();
    formData.append("file", file)
    formData.set("label", document.label)
    formData.set("description", document.description)
    return Axios.post<Doc, Doc>(`${SERVICE_URL}/api/devices/${device.id}/documents`, formData)
        // @ts-ignore
        .then(response => ({...response.data, createdAt: new Date(response.data.createdAt)}))
        // @ts-ignore
        .then(update => [...documents.data, update])
        .then(data => setDocuments({loading: false, error: undefined, data}))
  }

  const saveMed = (med: Med) => {
    if (!device) return Promise.reject('no device')
    const handleResponse = (resolve: Function, response: AxiosResponse<Med>, isNew: boolean) => {
      const medsdata = meds?.data || []
      const update = {...response.data, createdAt: new Date(response.data.createdAt)}
      // @ts-ignore
      const data = isNew ? [...medsdata, update] : meds.data.map(c => c.id === med.id ? update : c)
      setMeds({loading: false, data})
      resolve()
    }
    const handleError = (reject: Function, err: any) => {
      setMeds({loading: false, error: err})
      reject(err)
    }

    return new Promise<void>((resolve, reject) => {
      if (med.id) Axios.put<Med, AxiosResponse<Med>>(`${SERVICE_URL}/api/devices/${device.id}/meds/${med.id}`, med)
          .then(response => handleResponse(resolve, response, false))
          .catch(err => handleError(reject, err))
      else Axios.post<Med, AxiosResponse<Med>>(`${SERVICE_URL}/api/devices/${device.id}/meds`, med)
          .then(response => handleResponse(resolve, response, true))
          .catch(err => handleError(reject, err))
    })
  }

  const removeMed = (medId: string) => {
    if (!device) return Promise.reject('no device')
    const handleResponse = () => setMeds({loading: false, data: meds?.data?.filter(c => c.id !== medId)})
    const handleError = (err: any) => setMeds({loading: false, error: err})
    return Axios.delete<Med>(`${SERVICE_URL}/api/devices/${device.id}/meds/${medId}`)
        .then(() => handleResponse())
        .catch(err => handleError(err))
  }

  const savePathway = (pathway: PathwayConfig) => {
    if (!device) return Promise.reject('no device')
    return Axios.post<PathwayConfig, void>(`${SERVICE_URL}/api/devices/${device.id}/pathways/${pathway.code}`, pathway)
        .then(() => setPathways({loading: false, data: pathways?.data?.map(c => c.code === pathway.code ? pathway : c)}))
        .catch(err => setPathways({loading: false, error: err}))
  }

  return (
      <DeviceContext.Provider value={{
        device,
        deviceGroups,
        contacts,
        posts,
        postGroups,
        postGroup,
        metrics,
        metricsInterval,
        activity,
        activityInterval,
        insights,
        insightsInterval,
        alerts,
        alertsInterval,
        appointments,
        vitals,
        vitalsInterval,
        readoutPlans,
        appliances,
        documents,
        documentsInterval,
        meds,
        pathways,
        motionData,

        setDevice,
        saveDevice,
        saveContact,
        removeContact,
        saveAppointment,
        removeAppointment,
        addPost,
        savePostGroup,
        setPostGroup,
        setMetricsInterval,
        setActivityInterval,
        setInsightsInterval,
        setAlertsInterval,
        setVitalsInterval,
        setDocumentsInterval,
        saveDocument,
        saveMed,
        removeMed,
        savePathway

      }}>{children}</DeviceContext.Provider>
  );
}

export { DeviceContext, DeviceProvider };
