import { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { SafeArea } from 'capacitor-plugin-safe-area';
import { App as CapApp } from '@capacitor/app';

import { AuthProvider } from './contexts/AuthContext';
import { DataProvider } from './contexts/DataContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { DeviceProvider } from "./contexts/DeviceContext";
import { ThemeProvider } from "./contexts/ThemeContext";

import { Header } from './components/Header';
import { Contents } from './components/Contents';
import { NotificationPanel } from "./components/NotificationPanel";
import { PrivateRoute } from './PrivateRoute';
import { AlertProvider } from "./contexts/AlertContext";
import { Capacitor } from "@capacitor/core";
import { isIOS } from "./utils/Mobile";
import { AppInfo } from "@capacitor/app/dist/esm/definitions";
import { Footer } from "./components/Footer";

const SERVICE_URL = process.env.REACT_APP_SERVICE_URL || window.location.origin;

function App() {

  const [insets, setInsets] = useState({top: 0, bottom: 0});
  const [ appInfo, setAppInfo ] = useState<AppInfo>({ id: "", name: "", build: "", version: "" })

  const renderLoader = () => <div className="login-wallpaper"/>;
  const DashboardPage = lazy(() => import( /* webpackChunkName: "dashboard" */'./pages/Dashboard/DashboardPage'));
  const ProfilePage = lazy(() => import( /* webpackChunkName: "profile" */'./pages/Profile/ProfilePage'));
  const SettingsPage = lazy(() => import( /* webpackChunkName: "settings" */'./pages/Settings/SettingsPage'));
  const ResidentsPage = lazy(() => import( /* webpackChunkName: "residents" */'./pages/Residents/ResidentsPage'));
  const AlertsPage = lazy(() => import( /* webpackChunkName: "alerts" */'./pages/Alerts/AlertsPage'));
  const NoPage = lazy(() => import( /* webpackChunkName: "pages" */'./pages/NoPage'));
  const LoginPage = lazy(() => import( /* webpackChunkName: "pages" */'./pages/LoginPage'));
  const ForbiddenPage = lazy(() => import( /* webpackChunkName: "pages" */'./pages/ForbiddenPage'));
  const InvitationConfirmationPage = lazy(() => import( /* webpackChunkName: "pages" */'./pages/Invitation/InvitationConfirmationPage'));
  const ConsentPage = lazy(() => import( /* webpackChunkName: "pages" */'./pages/Consent/ConsentPage'));

  const href = window.location.href;
  const params = new URLSearchParams(window.location.search);
  const loginOptions = {screen_hint: params.get("screen_hint") || ''};

  useEffect(() => {
    if (isIOS()) {
      SafeArea.getSafeAreaInsets().then((values) => setInsets(values.insets));
      CapApp.getInfo().then((info) => setAppInfo(info));
    }
  }, []);

  console.log('Using service', SERVICE_URL);
  console.log("Rendering", Capacitor.getPlatform());

  const AppContents = () =>
      <DataProvider>
        <NotificationProvider>
          <AlertProvider>
            <Suspense fallback={renderLoader()}>
              <DeviceProvider>
                <BrowserRouter>
                  <ThemeProvider defaultTheme="lacasa">
                    <Header top={insets.top}/>
                    <NotificationPanel/>
                    <Contents>
                      <Switch>
                        <PrivateRoute path="/" exact component={DashboardPage}/>
                        <PrivateRoute path="/residents" exact component={ResidentsPage}/>
                        <PrivateRoute path="/alerts" exact component={AlertsPage}/>
                        <PrivateRoute path="/profile" exact component={ProfilePage}/>
                        <PrivateRoute path="/settings" exact component={SettingsPage}/>
                        <Route path="/auth" exact component={LoginPage}/>
                        <Route path="/403" exact component={ForbiddenPage}/>
                        <Route path="/404" exact component={NoPage}/>
                        <Redirect to="/"/>
                      </Switch>
                    </Contents>
                  </ThemeProvider>
                </BrowserRouter>
              </DeviceProvider>
            </Suspense>
          </AlertProvider>
        </NotificationProvider>
      </DataProvider>;


  const WebStruct = () => {
    const AppLoader = withAuthenticationRequired(AppContents, {
      loginOptions, onRedirecting: () => <div className="login-wallpaper"/>
    });
    return (
        <AuthProvider>
          <AppLoader/>
        </AuthProvider>);

  };

  const DialogStruct = () =>
      <Suspense fallback={renderLoader()}>
        <BrowserRouter>
          <Switch>
            <Route path="/invitations/:id" component={InvitationConfirmationPage}/>
            <Route path="/:deviceId/consent/:consentId" component={ConsentPage}/>
          </Switch>
        </BrowserRouter>
      </Suspense>;


  const IOSStruct = () =>
      <AuthProvider>
        <div className="position-relative" style={{paddingTop: insets.top}}>
          <AppContents/>
          <Footer version={appInfo.version} build={appInfo.build}/>
        </div>
      </AuthProvider>;


  if (isIOS()) return <IOSStruct/>; else if (href.includes('/invitations/') || href.includes('/consent/')) return <DialogStruct/>; else
    return <WebStruct/>;
}

export default App;