import { createContext, useContext, useEffect, useState } from "react";
import Axios from "axios";

import { AuthContext } from './AuthContext';
import { Config, Device, HeaderConfig, Loadable } from '../types'
import { LoadableObject } from "../types/LoadableObject";

interface updateAppearanceType {
  header?: HeaderConfig
  theme?: string
  bg?: string
}

interface DataContextType {
  config: LoadableObject<Config>
  devices: Loadable<Device>
  setDevices: (devices: any) => void
  reloadConfig: () => Promise<void>
  reloadDevices: () => Promise<void>
  updateAppearance: (appearance: updateAppearanceType) => Promise<any>
}

const loadableDevices: Loadable<Device> = {loading: true}
const loadableConfig: LoadableObject<Config> = {loading: true}
const initialContext: DataContextType = {
  config: loadableConfig,
  devices: loadableDevices,
  setDevices: () => {
  },
  reloadConfig: () => Promise.resolve(),
  reloadDevices: () => Promise.resolve(),
  updateAppearance: () => Promise.resolve(),
}
const DataContext = createContext<DataContextType>(initialContext);
const SERVICE_URL = process.env.REACT_APP_SERVICE_URL || window.location.origin;

function DataProvider({children}: any) {

  const {auth} = useContext(AuthContext)
  const [config, setConfig] = useState<LoadableObject<Config>>({loading: true})
  const [devices, setDevices] = useState<Loadable<Device>>({loading: true})

  useEffect(() => {
    if (auth) Promise.all([reloadConfig(), resolvePendingInvitations().then(reloadDevices)])
  }, [auth]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (devices.error) console.log("Error loading devices", devices.error)
    if (config.error) console.log("Error loading config", config.error)
  }, [devices, config])

  const resolvePendingInvitations = () => Axios.post(`${SERVICE_URL}/api/devices/pending-registration`)
      .then(() => console.log("Pending invitations resolved"))
      .catch(err => console.log("Error resolving pending invitations", err))

  const reloadConfig = () => {
    setConfig(() => ({loading: true}))
    return Axios.get(`${SERVICE_URL}/api/config`)
        .then(response => setConfig({loading: false, data: response.data}))
        .then(() => console.log("Config successfully loaded"))
        .catch(err => setConfig({loading: false, error: err}))
  }

  const reloadDevices = () => {
    setDevices(() => ({loading: true}))
    return Axios.get(`${SERVICE_URL}/api/devices`)
        .then(response => setDevices({loading: false, data: response.data}))
        .then(() => console.log("Devices successfully loaded"))
        .catch(err => setDevices({loading: false, error: err}));
  }

  const updateAppearance = (appearance: updateAppearanceType): Promise<any> => {
    return Axios.post(`${SERVICE_URL}/api/config/appearance`, appearance)
        // @ts-ignore
        .then(() => setConfig({...config, data: {...config.data, ...appearance}}))
  }

  return (
      <DataContext.Provider value={{
        config,
        devices,
        setDevices,
        reloadDevices,
        reloadConfig,
        updateAppearance,
      }}>{children}</DataContext.Provider>
  );
}

export { DataContext, DataProvider };
